<template>
  <v-layout>
    <base-list-view
      v-model="selected"
      title="Шаблоны документа"
      label-text-btn-create="Создать шаблон"
      label-text-search="Поиск шаблона документа"
      @onRefresh="init"
      @onDelete="del"
      :dataSource="dataSource"
      :headers="headers"
      :loading="loading"
      pageEditName="DocumentTemplateEdit"
      :exclude-settings-actions="['group']"
      :selectedItems="selected"
      @toggle-select-all="selectAllToggle"
      :sort-by.sync="sortBy"
      :item-class="(item) => (item.disabled ? 'disabled' : '')"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="isSelected"
          :ripple="false"
          :readonly="item.disabled"
          :disabled="item.disabled"
          @input="select($event)"
        ></v-simple-checkbox>
      </template>
      <div row slot="action" class="align-center d-inline-flex">
        <label
          class="tasks__action-show-all v-label blue-grey--text mr-1"
          style="font-size: 14px"
          >Показать архивные</label
        >

        <v-checkbox
          v-model="showCompleted"
          @change="init"
          hide-details
        ></v-checkbox>
      </div>
    </base-list-view>
  </v-layout>
</template>

<script>
import ListEditMixin from "../../mixins/ListEditMixin";
import DocumentTemplateService from "@/services/document/DocumentTemplateService";
import BaseListView from "../../layouts/BaseListView.vue";

export default {
  name: "view-DocumentTemplates",
  components: {
    BaseListView,
  },
  mixins: [ListEditMixin],
  data: function () {
    return {
      apiService: DocumentTemplateService,
      showCompleted: false,
      loading: true,
      sortBy: ["Name"],
      headers: [
        {
          text: "Название",
          value: "Name",
        },
        {
          text: "Тип",
          value: "DocumentType.Name",
          width: "20%",
        },
        {
          text: "Раздел деятельности",
          value: "ActivitySection.Name",
        },
        {
          text: "Частый",
          value: "IsOftenUsed",
          dataType: "boolean",
          align: "center",
          width: "0%",
        },
      ],
      dataSource: [],
      selected: [],
    };
  },
  watch: {
    showCompleted: function (val) {
      if (val && !this.headers.find((e) => e.value === "Archived")) {
        this.headers.push({
          text: "Добавлено в архив",
          value: "Archived",
          dataType: "Date",
        });
      } else if (!val && this.headers.find((e) => e.value === "Archived")) {
        this.headers = this.headers.filter((e) => e.value !== "Archived");
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    selectAllToggle() {
      const selectedCount = this.selected.length;
      const selectableCount = this.dataSource.filter(
        (item) => !item.disabled
      ).length;

      if (selectedCount !== selectableCount) {
        this.selected = this.dataSource.filter((item) => !item.disabled);
      } else {
        this.selected = [];
      }
    },
    DataFromServerNormalize(data) {
      for (let i = 0; i < data.length; i++) {
        data[i].disabled = data[i].IsSystem;
      }
      return data;
    },
    async init() {
      try {
        this.loading = true;
        const res = await DocumentTemplateService.get(null, this.showCompleted);

        this.dataSource = this.DataFromServerNormalize(res.data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped></style>
